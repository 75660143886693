import { validateOptionalPhoneNumberWithDialCode } from '@/utility/validation';
import * as yup from 'yup';

export const emailformSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be in a valid format like “name@example.com”')
    .required('Email is required.')
});

export const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be in a valid format like “name@example.com”')
    .required('Email is required.'),
  password: yup.string().required('Password is required.'),
  dialCodeId: yup.string(),
  phone: yup
    .string()
    .test(
      'phoneNumberValidation',
      'Invalid phone number',
      function (value) {
        return validateOptionalPhoneNumberWithDialCode(
          value,
          this.parent['dialCodeId']
        );
      }
    )
});
