import EyeVisible from '@/components/common/Icons/EyeVisible';
import Close from '@/components/common/IconsV2/Close';
import NextImage from '@/components/common/NextImage';
import RoundedButton from '@/components/common/RoundedButton';
import { useCallback, useState } from 'react';
import { CardWithHeader } from './CardWithHeader';
import style from './../CommunityPage.module.scss';
import LeadIcon from '@/components/common/IconsV2/LeadIcon';

export const SectionEmptyCard = ({
  title,
  subtitle,
  image,
  onClickHandler,
  showPlusIcon,
  showShadow = true,
  showClose = true,
  ctaText,
  isDemoCommunity
}) => {
  const [enableRemoveAnimation, setEnableRemoveAnimation] =
    useState(false);
  const [dismissed, setDismissed] = useState(false);

  const handleDismiss = () => {
    setEnableRemoveAnimation(true);
    setTimeout(() => setDismissed(true), 600);
  };

  const renderHeaderContent = useCallback(() => {
    return (
      <div className="flex flex-row items-center justify-between px-24 py-12 bg-npl-transparent-white-50">
        <div className="flex flex-row items-center gap-8 text-npl-text-icon-on-light-surface-secondary">
          <EyeVisible fillColor="rgba(27, 27, 24, 0.65)" /> Only you can
          see this
        </div>
        {showClose && (
          <button className="cursor-pointer" onClick={handleDismiss}>
            <Close />
          </button>
        )}
      </div>
    );
  }, []);

  const renderChildren = useCallback(() => {
    return (
      <div className="flex flex-col items-center justify-center p-24">
        {image && <NextImage {...image} />}
        <div className="mt-24 font-semibold text-center text-heading-md text-npl-text-icon-on-light-surface-primary">
          {title}
        </div>
        <div className="mt-8 mb-24 text-npl-text-icon-on-light-surface-secondary text-body-sm max-w-[520px] text-center">
          {subtitle}
        </div>
        <RoundedButton
          disabled={isDemoCommunity ? true : false}
          onClick={onClickHandler}
          customClassNames="bg-npl-neutral-dark-3 text-npl-text-icon-on-dark-primary hover:bg-npl-neutral-dark-9 "
          displayType={'custom'}
          size="md">
          <div className="flex flex-row items-center justify-center w-full gap-8">
            {showPlusIcon && <LeadIcon />}
            {ctaText}
          </div>
        </RoundedButton>
      </div>
    );
  }, [title, subtitle, ctaText]);

  if (dismissed) return null;

  return (
    <CardWithHeader
      headerContent={renderHeaderContent()}
      showShadow={showShadow}
      customClassNames={`border-dashed border-1 border-npl-transparent-black-50 ${
        enableRemoveAnimation && `${style['dismiss-card']}`
      }`}>
      {renderChildren()}
    </CardWithHeader>
  );
};
