import { getDayName, getMonthName } from '@/utility/dateHelper';
import { useCallback, useEffect, useState } from 'react';

import ChevronDown from '@/components/common/IconsV2/ChevronDown';
import EditPencil from '@/components/common/IconsV2/EditPencil';
import NextImage from '@/components/common/NextImage';
import RoundedButton from '@/components/common/RoundedButton';
import { getCommunityBannerImageLiveEvents } from '../utils';

const LiveEventsSection = ({
  upcomingEvents,
  pastEvents,
  showAdminContent,
  sectionTitle,
  handleEditEventsClick,
  isDemoCommunity
}) => {
  const maxPages = 4;
  const pageSize = 3;

  const [upcomingAmount, setUpcomingAmount] = useState(0);
  const [pastAmount, setPastAmount] = useState(0);
  const [showButton, setShowButton] = useState(true);
  const [currentPages, setCurrentPages] = useState(0);

  useEffect(() => {
    showMore();
  }, []);

  // if emough upcomingEvents show only upcoming, if not enough show some pastEvents also, if neither, show all and hide showMore button
  const showMore = useCallback(() => {
    let pagesToShow = currentPages + 1;

    if (upcomingEvents.length >= pagesToShow * pageSize) {
      setUpcomingAmount(pagesToShow * pageSize);
    } else if (
      upcomingEvents.length + pastEvents.length >=
      pagesToShow * pageSize
    ) {
      setUpcomingAmount(upcomingEvents.length);
      setPastAmount(pagesToShow * pageSize - upcomingEvents.length);
    } else {
      setUpcomingAmount(upcomingEvents.length);
      setPastAmount(pastEvents.length);
      setShowButton(false);
    }

    if (pagesToShow >= maxPages) {
      setShowButton(false);
    }
    setCurrentPages(pagesToShow);
  }, [currentPages, pastEvents, upcomingEvents]);

  const renderLiveEvent = (liveEvent, index) => {
    const date = new Date(liveEvent?.startTime.replace(/-/g, '/'));
    return (
      <a
        className="flex flex-row p-12 cursor-pointer rounded-8 group hover:bg-npl-neutral-light-2"
        target="_blank"
        href={`${window.location.pathname}${liveEvent?.slug}`}
        key={index}
        rel="noreferrer">
        <div className="rounded-8 overflow-hidden mr-20 relative w-[112px] min-w-[112px] h-56">
          <NextImage
            {...getCommunityBannerImageLiveEvents(liveEvent?.bannerImg)}
          />
        </div>
        <div className="flex flex-col flex-grow">
          <div className="mb-4 font-semibold text-npl-text-icon-on-light-surface-primary text-label-md">
            {liveEvent?.title}
          </div>
          <div className="font-medium text-npl-text-icon-on-light-surface-secondary text-label-md">
            {`${getDayName(
              date.getDay(),
              true
            )}, ${date.getDate()} ${getMonthName(date.getMonth(), true)}`}
          </div>
        </div>
        <div className="flex-row items-center justify-end invisible hidden md:flex group-hover:visible">
          <span className="font-medium text-label-sm text-npl-text-icon-on-light-surface-primary">
            View
          </span>
          <ChevronDown
            customClassNames="-rotate-90 ml-6"
            width="16"
            height="16"
          />
        </div>
      </a>
    );
  };

  const renderEvents = (heading, events, amount) => {
    return (
      <div>
        <div className="mb-12 font-medium uppercase text-npl-text-icon-on-light-surface-secondary text-label-md">
          {heading}
        </div>
        <div className="flex flex-col gap-24">
          {events
            ?.slice(0, amount)
            ?.map((event, index) => renderLiveEvent(event, index))}
        </div>
      </div>
    );
  };

  return (
    <div
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-easing="ease"
      data-aos-duration="500"
      data-aos-once={true}
      className="flex flex-col w-full px-24 py-40 shadow-lg bg-white-default rounded-28">
      <div
        className={`text-heading-md relative w-full font-semibold flex items-center h-[44px] text-neutral-10`}>
        {sectionTitle}
        {showAdminContent && (
          <div className="absolute top-0 right-0 hidden lg:block">
            <RoundedButton
              disabled={isDemoCommunity ? true : false}
              displayType={'neutral'}
              onClick={handleEditEventsClick}>
              <div className="flex flex-row items-center">
                {' '}
                <EditPencil
                  customClassNames={'w-16 h-16 mr-8'}
                  fill="#FBFCFF"
                />{' '}
                Edit
              </div>
            </RoundedButton>
          </div>
        )}
      </div>
      <div className="relative w-full mt-24 mb-24">
        {upcomingAmount > 0 &&
          renderEvents('Upcoming', upcomingEvents, upcomingAmount)}
        {pastAmount > 0 && (
          <div className="w-full mt-16">
            {renderEvents('Past', pastEvents, pastAmount)}
          </div>
        )}
      </div>
      {showButton && (
        <div className="flex items-center justify-center w-full mt-24 row ">
          <button
            onClick={showMore}
            className="px-24 py-12 font-semibold bg-transparent text-label-lg text-neutral-10 border-1 border-light-aa hover:bg-light-e0 rounded-50">
            See More
          </button>
        </div>
      )}
    </div>
  );
};

export default LiveEventsSection;
