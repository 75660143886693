import FloatingLabelInput from '@/components/common/Form/FloatingLabelInput';
import FormField from '@/components/common/Form/FormField';
import RoundedButton from '@/components/common/RoundedButton';
import { useAuthContext } from '@/contexts/AuthContext';
import { useUserSessionContext } from '@/contexts/UserSessionContext';
import {
  resetPasswordLink,
  recoverPasswordLink
} from '@/utility/commonPageConstants';
import { dialCodeOptions } from '@/utility/formHelpers';
import { buildUrlWithQueryParams } from '@/utility/urlHelpers';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { useMemo, useEffect, useRef } from 'react';
import SocialLogins from '../../../SocialLogins/SocialLogins';
import style from './LoginForm.module.scss';

const LoginForm = ({
  onChange,
  onSubmit,
  error,
  formRef,
  isLoading,
  loginInputErrors,
  dialCodeId,
  phoneNumber,
  isSignUpForm = false,
  showPhoneField,
  isDisabledCta,
  defaultEmail,
  postLoginCallback,
  isResetPassword
}) => {
  const className = 'c-LoginForm';

  const { isWebView } = useUserSessionContext();
  const { handleSocialAuth, socialLoginError, isLoggedIn, user } =
    useAuthContext();
  const isLoggedInRef = useRef(isLoggedIn);

  useEffect(() => {
    if (isLoggedIn !== isLoggedInRef.current && postLoginCallback) {
      postLoginCallback({
        user: user
      });
      isLoggedInRef.current = isLoggedIn;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const baseClassName = classNames(className, style[className]);
  const messageClassName = `${className}__message`;
  const errorMessageClassName = classNames(
    style[messageClassName],
    style[`${messageClassName}--error`]
  );

  const formattedDialCodeOptions = useMemo(
    () =>
      dialCodeOptions.reduce((result, { label, value }) => {
        if (value === Number(dialCodeId)) {
          result.push({ value, label: label.split(' ')[0] });
        } else {
          result.push({ label, value });
        }
        return result;
      }, []),
    [dialCodeId, dialCodeOptions]
  );

  return (
    <div className={baseClassName}>
      {!isWebView && !isDisabledCta ? (
        <>
          <SocialLogins
            handleSocialAuth={handleSocialAuth}
            socialLoginError={socialLoginError}
            isRounded={true}
          />
          <div className={style[`${className}__or`]}>
            <div className={style[`${className}__or__text`]}>OR</div>
          </div>
        </>
      ) : null}
      <form
        noValidate
        ref={formRef}
        onSubmit={onSubmit}
        className={style[`${className}__form`]}>
        {/* <div className={style[`${className}__form__input__wrapper`]}> */}
        <FloatingLabelInput
          // className={style[`${className}__form__input`]}
          onChange={onChange}
          type="email"
          name="email"
          placeholder="Enter your email"
          label={isSignUpForm ? <p>Email</p> : 'Email'}
          defaultValue={defaultEmail}
          // required
          v2
          error={loginInputErrors['email']}
        />
        {/* </div> */}
        {isSignUpForm && showPhoneField && (
          <div className={`flex flex-row items-end`}>
            <div className="flex-shrink px-2">
              <FormField
                fieldType="select"
                name="dialCodeId"
                label="Phone Number"
                onChange={onChange}
                value={dialCodeId}
                dataTestId={className}
                optionsList={formattedDialCodeOptions}
                v2
                isFloating={true}
              />
            </div>
            <div
              className={`flex-1 px-2 ${
                style[`${className}__form__margin-none`]
              }`}>
              <FormField
                fieldType="input"
                name="phone"
                onChange={onChange}
                value={phoneNumber}
                placeholder="Enter your phone number"
                dataTestId={className}
                v2
                isFloating={true}
              />
            </div>
          </div>
        )}
        <div className="mt-16">
          <FloatingLabelInput
            // className={style[`${className}__form__input`]}
            onChange={onChange}
            type="password"
            name="password"
            placeholder="Enter Password"
            label="Password"
            eyeIcon
            // required
            v2
            customLabelClasses="text-heading-sm"
            error={loginInputErrors['password']}
          />
        </div>
        <div className="text-right">
          <a
            title="Forgot Password"
            href={buildUrlWithQueryParams(
              isResetPassword ? recoverPasswordLink : resetPasswordLink,
              {
                email: defaultEmail
              }
            )}
            rel="noreferrer"
            target="_blank"
            className={style[`${className}__form__link`]}>
            {isResetPassword
              ? 'Reset your password'
              : 'Forgot your password?'}
          </a>
        </div>
        {error && <div className={errorMessageClassName}>{error}</div>}
        {/* {show1on1Card && <OneOnOneCard />} */}
        <RoundedButton
          displayType="primary"
          type="submit"
          customClassNames="w-full flex justify-center mt-16"
          isLoading={isLoading}>
          Login
        </RoundedButton>
      </form>
    </div>
  );
};

LoginForm.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  postLoginCallback: PropTypes.func,
  error: PropTypes.string,
  formRef: PropTypes.object,
  isLoading: PropTypes.bool,
  show1on1Card: PropTypes.bool,
  loginInputErrors: PropTypes.object
};

export default LoginForm;
