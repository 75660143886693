import PropTypes from 'prop-types';
import React from 'react';
import ReactDom from 'react-dom';
import style from './SuccessToast.module.scss';
const SuccessToast = (props) => {
  const centerHorizontal = props.centerHorizontal
    ? 'left-[50%] translate-x-[-50%] w-[fit-content]'
    : '';
  return ReactDom.createPortal(
    <div
      className={`c-success-toast h-[fit-content]  ${
        style['sucess-toast']
      } ${props.position} ${style[props.top]} ${style[props.right]} ${
        style[props.left]
      } ${style[props.bottom]} ${centerHorizontal} ${
        props.customClassnames
      }`}>
      <p className=""> {props.msg} </p>
    </div>,
    document.getElementById('modal')
  );
};

SuccessToast.propTypes = {
  msg: PropTypes.string.isRequired,
  position: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  customClassnames: PropTypes.string
};

export default SuccessToast;
