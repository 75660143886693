// import AppleSignin from 'react-apple-signin-auth';
import Button from '../Button';
// import FacebookLogin from './facebook-login-render-props';
import LoadingCircle from '../LoadingCircle';
import { NO_COMMUNITY_ERROR } from '@/pages/login/constants';
import classNames from 'classnames';
import config from '../../../utility/config';
import style from './SocialLogins.module.scss';
import { useGoogleLogin } from '@react-oauth/google';
import { useUserSessionContext } from '../../../contexts/UserSessionContext/UserSessionContext';

const {
  googleClientId,
  // fbAppId,
  // appleClientId,
  // appleRedirectLink,
  socialLoginsOnSignup,
  socialLoginsOnLogin
} = config;

const SocialLogins = ({
  isSignup,
  isLoading,
  handleSocialAuth,
  socialLoginError,
  isRounded,
  isCommunityAdmin = true,
  shouldRedirect = true,
  hideGoogle = false
  // hideFacebook = false,
  // hideApple = false
}) => {
  const { isWebView } = useUserSessionContext();
  const className = 'c-SocialLogins';
  const baseClassName = classNames(className, style[className]);

  const socialBtnClass = `${className}__social-btn-wrapper`;
  const googleBtnClass = classNames(
    `${socialBtnClass}--google`,
    style[socialBtnClass],
    style[`${socialBtnClass}--google`]
  );
  // const fbBtnClass = classNames(
  //   `${socialBtnClass}--facebook`,
  //   style[socialBtnClass],
  //   style[`${socialBtnClass}--facebook`]
  // );
  // const appleBtnClass = classNames(
  //   `${socialBtnClass}--apple`,
  //   style[socialBtnClass],
  //   style[`${socialBtnClass}--apple`]
  // );

  const showCTAButtonsFromConfig = isSignup
    ? socialLoginsOnSignup?.split(',')
    : socialLoginsOnLogin?.split(',');

  // const showAppleLoginCta =
  //   !isWebView &&
  //   appleClientId &&
  //   showCTAButtonsFromConfig?.includes('apple') &&
  //   !hideApple;

  const showGoogleLoginCta =
    !isWebView &&
    googleClientId &&
    showCTAButtonsFromConfig?.includes('google') &&
    !hideGoogle;

  const googleOnClick = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (res) => {
      console.log('>> googleOnClick onSuccess ', res);
      handleSocialAuth(res, 'google', isCommunityAdmin, shouldRedirect);
    },
    onError: (res) => {
      console.log('>> googleOnClick onError ', res);
      handleSocialAuth(res, 'google', isCommunityAdmin, shouldRedirect);
    }
  });

  if (isWebView) return null;

  return (
    <div className={baseClassName}>
      {isLoading && <LoadingCircle />}
      {socialLoginError && socialLoginError !== NO_COMMUNITY_ERROR && (
        <p className={style[`${className}__err-msg`]}>
          {socialLoginError}
        </p>
      )}

      {/* Google Login Button */}
      {showGoogleLoginCta ? (
        <>
          <div className={googleBtnClass}>
            <Button
              fluid
              onClick={() => googleOnClick()}
              customClassNames={isRounded && '!rounded-[48px]'}
              data-test-id="google-login-btn">
              {isSignup ? 'Sign up with Google' : 'Continue with Google'}
            </Button>
          </div>
        </>
      ) : null}

      {/* Facebook Login Btn */}
      {/* {fbAppId &&
        showCTAButtonsFromConfig?.includes('facebook') &&
        !hideFacebook && (
          <FacebookLogin
            appId={fbAppId}
            fields="name,first_name,last_name,email,picture.type(large)"
            autoLoad={false}
            disableMobileRedirect={true}
            callback={(res) =>
              handleSocialAuth(
                res,
                'facebook',
                isCommunityAdmin,
                shouldRedirect
              )
            }
            render={(renderProps) => {
              const { onClick } = renderProps;
              return (
                <div className={fbBtnClass}>
                  <Button
                    fluid
                    onClick={onClick}
                    customClassNames={isRounded && '!rounded-[48px]'}>
                    {isSignup
                      ? 'Sign up with Facebook'
                      : 'Continue with Facebook'}
                  </Button>
                </div>
              );
            }}
          />
        )} */}

      {/* Apple login button */}
      {/* {showAppleLoginCta && (
        <AppleSignin
          // Auth options passed to AppleID.auth.init()
          authOptions={{
            clientId: appleClientId,
            scope: 'email name ',
            redirectURI: appleRedirectLink,
            state: 'state',
            nonce: 'nonce',
            usePopup: true
          }}
          uiType="light"
          render={(props) => (
            <div className={appleBtnClass}>
              <Button
                fluid
                {...props}
                customClassNames={isRounded && '!rounded-[48px]'}>
                {isSignup ? 'Sign up with Apple' : 'Continue with Apple'}
              </Button>
            </div>
          )}
          onError={(res) =>
            handleSocialAuth(
              res,
              'apple',
              isCommunityAdmin,
              shouldRedirect
            )
          }
          onSuccess={(res) =>
            handleSocialAuth(
              res,
              'apple',
              isCommunityAdmin,
              shouldRedirect
            )
          }
        />
      )} */}
    </div>
  );
};
SocialLogins.defaultProps = {
  isRounded: false
};

export default SocialLogins;
