import React, { useCallback, useLayoutEffect, useState } from 'react';

import CloseIcon from '../Icons/CloseIcon';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import style from './BlurModal.module.scss';
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import useAos from '@/hooks/useAos';

const sizesMap = {
  sm: 'max-w-300',
  md: 'max-w-500',
  lg: 'max-w-800',
  xl: 'max-w-1140',
  default: 'max-w-768' //fallback
};

const BlurModal = ({
  open,
  onClose,
  showCloseIcon = true,
  hideGutters,
  closeIconHoverText,
  closeOnBackdropClick,
  customBaseClass,
  customContainerClass,
  customInnerClass,
  customCloseIconClass,
  children,
  size,
  withOutsideCloseIcon,
  showLeftIcon,
  leftIcon,
  containerBackgroundTransparent,
  customBackdropClassNames
}) => {
  useAos({
    duration: 500,
    ones: true
  });
  const className = 'c-BlurModal';
  const [_document, setDocument] = useState(null);

  const baseClassNames = classNames(className, style[className], {
    [style[`${className}--open`]]: open
  });
  const backDropFilter = containerBackgroundTransparent
    ? 'backdrop-blur-lg	'
    : '';

  const containerClassNames = `${classNames(
    `${className}__container`,
    containerBackgroundTransparent === true &&
      style[`${className}__transparent_white_color`],
    style[`${className}__container`],
    hideGutters && style[`${className}__container__no-gutter`],
    sizesMap?.[size]
  )}  ${customContainerClass}`;

  const innerClassNames = classNames(style[`${className}__inner`], {
    [customInnerClass]: !!customInnerClass
  });

  const backdropClassNames = classNames(
    style[`${className}__backdrop`],
    backDropFilter,
    customBackdropClassNames
  );

  const closeModalIconClassName = classNames(
    `${className}__close-modal`,
    style[`${className}__close-modal`],
    {
      [`${style[`${className}__outside-close-modal`]}`]:
        withOutsideCloseIcon,
      [customCloseIconClass]: !!customCloseIconClass
    }
  );

  const modalEl = _document?.getElementById('modal');

  const handleBackdropClick = () => {
    if (closeOnBackdropClick && !containerBackgroundTransparent) onClose();
  };

  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (document.body && open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (document.body)
        return (document.body.style.overflow =
          originalStyle === 'hidden' ? 'initial' : originalStyle);
    };
  }, [open]);

  // Access document in Next after page renders
  useLayoutEffect(() => {
    setDocument(document);
  }, []);

  const renderCloseIcon = useCallback(
    () =>
      showCloseIcon && (
        <div
          className={`${closeModalIconClassName}  bg-npl-neutral-dark-3 hover:!bg-npl-neutral-dark-6`}
          onClick={onClose}>
          {closeIconHoverText ? (
            <div className={style[`${className}__tooltip`]}>
              <span className={style[`${className}__tooltiptext`]}>
                {closeIconHoverText}
              </span>
              <CloseIcon fill="#fff" />
            </div>
          ) : (
            <CloseIcon fill="#fff" />
          )}
        </div>
      ),
    [closeIconHoverText, closeModalIconClassName, onClose, showCloseIcon]
  );

  const renderLeftIcon = useCallback(
    () =>
      showLeftIcon && (
        <div
          className={`${className}__left-icon ${
            style[`${className}__left-icon`]
          }`}>
          {leftIcon}
        </div>
      ),
    [showLeftIcon, leftIcon]
  );

  if (!modalEl) return null;

  return createPortal(
    <div
      className={`${baseClassNames} ${customBaseClass} animate-fadeInModal`}
      data-testid="modal">
      <div className={backdropClassNames} onClick={handleBackdropClick} />
      {withOutsideCloseIcon && renderCloseIcon()}
      <div className={`${containerClassNames}`}>
        {showLeftIcon && renderLeftIcon()}
        {!withOutsideCloseIcon && renderCloseIcon()}

        <div className={`${innerClassNames}`}>{children}</div>
      </div>
    </div>,
    modalEl
  );
};

BlurModal.defaultProps = {
  size: 'default',
  open: false,
  closeOnBackdropClick: true,
  showLeftIcon: false,
  hideGutters: false,
  closeIconHoverText: '',
  customBaseClass: '',
  customContainerClass: '',
  customInnerClass: '',
  customBackdropClassNames: ''
};

// No need to pass in onClose function if showCloseIcon is false, by default it is true
BlurModal.propTypes = {
  size: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  hideGutters: PropTypes.bool,
  children: PropTypes.any.isRequired,
  closeIconHoverText: PropTypes.string,
  closeOnBackdropClick: PropTypes.bool,
  customBaseClass: PropTypes.string.isRequired,
  customContainerClass: PropTypes.string.isRequired,
  customInnerClass: PropTypes.string.isRequired,
  withOutsideCloseIcon: PropTypes.bool,
  showLeftIcon: PropTypes.bool,
  leftIcon: PropTypes.object,
  customBackdropClassNames: PropTypes.string
};

export default BlurModal;
