import { useEffect, useRef, useState } from 'react';

export const SeeMoreWrapper = ({
  showInitially,
  customPreviewHeight,
  children,
  customSeeMoreButtonClasses,
  customSeeMoreContainerClasses
}) => {
  const [showMore, setShowMore] = useState(showInitially);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [seeMoreText, setSeeMoreText] = useState('...See More');
  const previewHeight = customPreviewHeight || 96;

  const seeMoreRef = useRef(null);

  const toggleSeeMore = () => {
    setShowMore(!showMore);
    setSeeMoreText(showMore ? '...See More' : 'See Less');
  };

  useEffect(() => {
    if (seeMoreRef) {
      const { offsetHeight } = seeMoreRef.current;
      setShowSeeMore(offsetHeight > previewHeight);
    }
  }, [showMore]);

  return (
    <div
      className="c-SeeMoreWrapper relative overflow-hidden h-full transition-[max-height] duration-500 ease-in-out"
      style={{ maxHeight: showMore ? '5000px' : previewHeight }}>
      <div ref={seeMoreRef} className="whitespace-pre-wrap">
        {children}
      </div>
      {showSeeMore && (
        <div
          className={`${
            showMore ? 'flex justify-end' : 'absolute'
          } bottom-0 right-0 px-8 py-2 pl-96 bg-gradient-to-r from-transparent via-npl-base-white to-npl-base-white ${customSeeMoreContainerClasses}`}>
          <button
            type="button"
            className={`text-label-lg font-semibold text-npl-text-icon-on-light-surface-tertiary ${customSeeMoreButtonClasses}`}
            onClick={toggleSeeMore}>
            {seeMoreText}
          </button>
        </div>
      )}
    </div>
  );
};
