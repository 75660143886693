import { useCallback } from 'react';
import { CardWithHeader } from './CardWithHeader';
import UnlockIcon from '@/components/common/IconsV2/UnlockIcon';
import style from './../CommunityPage.module.scss';
import { getFirstLetters } from '../utils';
import RoundedButton from '@/components/common/RoundedButton';

export const NftSection = ({
  nfts,
  onClickHandler,
  signupCtaLabel,
  ctaDisabled
}) => {
  const renderHeaderContent = useCallback(() => {
    return (
      <div className="flex flex-row items-center gap-8 whitespace-nowrap py-12 px-24 bg-npl-neutral-light-2 font-medium text-npl-text-icon-on-light-surface-secondary">
        <UnlockIcon fill="rgba(27, 27, 24, 0.65)" /> Get access with these
        NFTs
      </div>
    );
  }, []);

  const getShortenedAdress = (address) => {
    return `${address?.slice(0, 6)}...${address?.slice(
      address?.length - 4,
      address?.length
    )}`;
  };

  const renderChildren = useCallback(() => {
    return (
      <div
        className={`flex flex-col justify-center gap-18 p-24 ${
          !onClickHandler && 'pb-40'
        }`}>
        {nfts?.map((nftObj, index) => (
          <div
            className="flex flex-row items-center h-48 overflow-hidden relative"
            key={index}>
            <div
              className={`${style['hexagon']} ${
                style[`color-${index % 5}`]
              } flex flex-col justify-center items-center`}>
              <div
                className={`uppercase absolute font-medium text-label-lg`}>
                {getFirstLetters(nftObj?.collectionName)?.slice(0, 2)}
              </div>
            </div>
            <div className="flex flex-col items-start ml-8 overflow-hidden">
              <div className="mb-8 text-label-lg text-npl-text-icon-on-light-surface-primary font-medium whitespace-nowrap overflow-ellipsis max-w-[100%] overflow-hidden">
                {nftObj?.collectionName}
              </div>
              <div className="px-8 py-2 rounded-full bg-npl-neutral-light-3 text-npl-neutral-light-9 font-medium text-label-sm">
                {(nftObj?.contractAddress &&
                  getShortenedAdress(nftObj?.contractAddress)) ||
                  nftObj?.symbol}
              </div>
            </div>
          </div>
        ))}
        {onClickHandler && (
          <RoundedButton
            displayType={'primary'}
            size="lg"
            customClassNames={'mt-16 mb-16 justify-center'}
            disabled={ctaDisabled}
            onClick={onClickHandler}>
            {signupCtaLabel}
          </RoundedButton>
        )}
      </div>
    );
  }, [nfts]);

  return (
    <CardWithHeader
      headerContent={renderHeaderContent()}
      customClassNames={`max-h-[420px]`}
      customBodyClassNames="overflow-y-auto">
      {renderChildren()}
    </CardWithHeader>
  );
};
