import { useCallback } from 'react';
import { getPlatformDetails } from '../utils';
import { CardWithHeader } from './CardWithHeader';

export const PlatformCard = ({ platform, communityName }) => {
  const headerTitle = 'This community is connected to';
  const platformDetails = getPlatformDetails(platform);
  const renderHeaderContent = useCallback(() => {
    return (
      <div className="w-full h-full px-24 py-12 font-medium bg-npl-transparent-white-50 text-label-lg text-npl-text-icon-on-light-surface-secondary">
        {headerTitle}
      </div>
    );
  }, [headerTitle]);

  const renderBodyContent = useCallback(() => {
    return (
      <div className="flex flex-col justify-start w-full h-full p-24 pb-40">
        <div className="mb-8">
          {platformDetails?.getIcon({
            customClassNames: 'w-40 h-40',
            width: 40,
            height: 40
          })}
        </div>
        <div className="mb-8 font-semibold text-heading-md text-npl-text-icon-on-light-surface-primary">
          {platformDetails?.displayName}
        </div>
        <div className="text-body-sm text-npl-text-icon-on-light-surface-secondary">
          {platformDetails?.getJoinText(communityName)}
        </div>
      </div>
    );
  }, [platformDetails]);

  return (
    <CardWithHeader headerContent={renderHeaderContent()}>
      {renderBodyContent()}
    </CardWithHeader>
  );
};
