import BlurModal from '@/components/common/BlurModal';
import { NftSection } from './NftSection';

export const NftModal = ({
  show,
  onClose,
  onCTAClick,
  nfts,
  ctaLabel,
  ctaDisabled
}) => {
  return (
    <BlurModal
      open={show}
      onClose={onClose}
      customContainerClass="bg-transparent max-w-500">
      <NftSection
        nfts={nfts}
        signupCtaLabel={ctaLabel}
        onClickHandler={onCTAClick}
        ctaDisabled={ctaDisabled}></NftSection>
    </BlurModal>
  );
};
