import { useEffect, useMemo, useState } from 'react';

import { openInNewTab } from '@/components/common/Editor/utils/openInNewTab';
import HeadlessSelect from '@/components/common/Form/HeadlessSelect/HeadlessSelect';
import CheckIcon from '@/components/common/IconsV2/CheckIcon';
import ChevronDown from '@/components/common/IconsV2/ChevronDown';
import EditPencil from '@/components/common/IconsV2/EditPencil';
import NextImage from '@/components/common/NextImage';
import RoundedButton from '@/components/common/RoundedButton';
import Icon from '@/icons/index';
import { getCourseBannerImgData } from '../utils';

const DEAFULT_CLASSES_TO_SHOW = 4;
const SORT_OPTIONS = {
  NEWEST: 'newest',
  OLDEST: 'oldest',
  PRICE_LOW_TO_HIGH: 'priceLowToHigh',
  PRICE_HIGH_TO_LOW: 'priceHighToLow',
  AZ: 'az',
  ZA: 'za',
  DEFAULT: 'default'
};
const SORT_OPTIONS_LABELS = {
  [SORT_OPTIONS.NEWEST]: 'Newest',
  [SORT_OPTIONS.OLDEST]: 'Oldest',
  [SORT_OPTIONS.PRICE_LOW_TO_HIGH]: 'Price: Low to High',
  [SORT_OPTIONS.PRICE_HIGH_TO_LOW]: 'Price: High to Low',
  [SORT_OPTIONS.AZ]: 'A-Z',
  [SORT_OPTIONS.ZA]: 'Z-A'
};

const ClassesSection = ({
  courses,
  showAdminContent,
  sectionTitle,
  handleEditClick,
  isDemoCommunity,
  enableSortOptions = false
}) => {
  const [numberOfClassesToShow, setNumberOfClassesToShow] = useState(
    DEAFULT_CLASSES_TO_SHOW
  );
  const [sortOption, setSortOption] = useState(SORT_OPTIONS.DEFAULT);
  const sortedCourses = useMemo(
    () =>
      courses?.sort((a, b) => {
        switch (sortOption) {
          case SORT_OPTIONS.DEFAULT:
            return a.index - b.index;
          case SORT_OPTIONS.NEWEST:
            return new Date(b?.createdAt) - new Date(a?.createdAt);
          case SORT_OPTIONS.OLDEST:
            return new Date(a?.createdAt) - new Date(b?.createdAt);
          case SORT_OPTIONS.PRICE_LOW_TO_HIGH:
            if (a?.access === 'paid' && b?.access !== 'paid') {
              return 1;
            }
            if (a?.access !== 'paid' && b?.access === 'paid') {
              return -1;
            }
            if (a?.access !== 'paid' && b?.access !== 'paid') {
              return new Date(b?.createdAt) - new Date(a?.createdAt);
            }
            return a?.amount - b?.amount;
          case SORT_OPTIONS.PRICE_HIGH_TO_LOW:
            if (a?.access === 'paid' && b?.access !== 'paid') {
              return -1;
            }
            if (a?.access !== 'paid' && b?.access === 'paid') {
              return 1;
            }
            if (a?.access !== 'paid' && b?.access !== 'paid') {
              return new Date(b?.createdAt) - new Date(a?.createdAt);
            }
            return b?.amount - a?.amount;
          case SORT_OPTIONS.AZ:
            return a?.title.localeCompare(b?.title);
          case SORT_OPTIONS.ZA:
            return b?.title.localeCompare(a?.title);
          default:
            return new Date(b?.createdAt) - new Date(a?.createdAt);
        }
      }),
    [courses, sortOption]
  );

  const [classesToShow, setClassesToShow] = useState(
    sortedCourses?.slice(0, DEAFULT_CLASSES_TO_SHOW)
  );

  const showMoreClasses = () => {
    setNumberOfClassesToShow(
      numberOfClassesToShow + DEAFULT_CLASSES_TO_SHOW
    );
  };
  useEffect(() => {
    setClassesToShow(sortedCourses?.slice(0, numberOfClassesToShow));
  }, [numberOfClassesToShow, sortedCourses, sortOption]);

  const renderCourse = (course) => {
    const itemCount =
      (course?.videoCount ?? 0) + (course?.externalLinkCount ?? 0);
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1
    });

    const currency = course?.currency || 'USD';

    return (
      <div
        className="flex flex-col p-12 hover:bg-npl-neutral-light-2 rounded-12 group"
        role="button"
        tabIndex={0}
        onClick={() => openInNewTab(course?.publicUrl)}>
        <div className="rounded-12 overflow-hidden mb-12 relative w-full aspect-[16/9] md:mr-20">
          <NextImage {...getCourseBannerImgData(course?.thumbnail)} />
        </div>
        <div className="flex items-stretch">
          <div className="flex flex-col flex-grow">
            <div className="mb-8 font-semibold text-npl-text-icon-on-light-surface-primary text-label-md">
              {course?.title}
            </div>
            <div className="flex flex-row items-center font-medium text-npl-text-icon-on-light-surface-secondary text-label-sm">
              {course?.access === 'paid' ? (
                <div className="flex flex-row items-stretch pr-4 bg-npl-neutral-light-3 rounded-4">
                  <div className="flex items-center mr-6 bg-npl-pink-light-11 rounded-l-4">
                    <Icon name="star-outline" width="14" height="14" />
                  </div>
                  <span className="my-2">
                    {`${currency} ${currencyFormatter.format(
                      course?.amount / 100
                    )}`}
                  </span>
                </div>
              ) : (
                'Free'
              )}
              <span className="mx-8">•</span>
              {!!itemCount && (
                <div className="flex flex-row items-center">
                  {itemCount} file
                  {itemCount > 1 && 's'}
                </div>
              )}
            </div>
            {/* <div className="font-medium text-npl-text-icon-on-light-surface-secondary text-label-md"></div> */}
          </div>
          <div className="flex flex-row items-center justify-end invisible group-hover:visible">
            <span className="font-medium text-label-sm text-npl-text-icon-on-light-surface-primary">
              View
            </span>
            <ChevronDown
              customClassNames="-rotate-90 ml-6"
              width="16"
              height="16"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCourses = () => {
    return (
      <div>
        <div className="flex flex-col md:grid md:grid-cols-2">
          {classesToShow?.map((course) => renderCourse(course))}
        </div>
      </div>
    );
  };

  return (
    <div
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-easing="ease"
      data-aos-duration="500"
      data-aos-once={true}
      className="flex flex-col w-full px-24 py-40 shadow-lg bg-white-default rounded-28">
      <div className="relative w-full flex items-center h-[44px]">
        <span className="flex-grow font-semibold text-heading-md text-neutral-10">
          {sectionTitle}
        </span>
        {enableSortOptions &&
          !showAdminContent &&
          sortedCourses?.length > 1 && (
            <HeadlessSelect
              options={Object.values(SORT_OPTIONS).map((f) => ({
                value: f
              }))}
              value={sortOption}
              customWidth={true}
              onChange={setSortOption}
              renderField={({ option, isOpen }) => (
                <RoundedButton
                  displayType="outline"
                  size="sm"
                  customInnerClassNames="min-w-[150px] w-full flex items-center justify-between">
                  {SORT_OPTIONS_LABELS[sortOption]}
                  <ChevronDown
                    customClassNames={`${
                      isOpen ? 'rotate-180' : ''
                    } transition-transform ease-in-out`}
                    width={16}
                    height={16}
                  />
                </RoundedButton>
              )}
              renderOption={({ value, option, handleOptionClick }) => (
                <div
                  className={`flex flex-row px-16 ${
                    option.disabled ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  role={'button'}
                  tabIndex={0}
                  onClick={() => {
                    if (!option.disabled) {
                      handleOptionClick(option);
                    }
                  }}>
                  <div className="flex flex-col flex-grow p-8">
                    <span className="text-body-sm text-npl-text-icon-on-light-surface-primary">
                      {SORT_OPTIONS_LABELS[option.value]}
                    </span>
                  </div>
                  <div className="flex items-center justify-center">
                    {option.value === value && <CheckIcon />}
                  </div>
                </div>
              )}
            />
          )}
        {showAdminContent && (
          <div className="absolute top-0 right-0 hidden lg:block">
            <RoundedButton
              disabled={isDemoCommunity ? true : false}
              displayType={'neutral'}
              onClick={handleEditClick}>
              <div className="flex flex-row items-center">
                {' '}
                <EditPencil
                  customClassNames={'w-16 h-16 mr-8'}
                  fill="#FBFCFF"
                />{' '}
                Edit
              </div>
            </RoundedButton>
          </div>
        )}
      </div>
      <div className="relative w-full mt-24 mb-24">{renderCourses()}</div>
      {courses?.length > numberOfClassesToShow && (
        <div className="flex items-center justify-center w-full mt-24 row">
          <button
            onClick={showMoreClasses}
            className="px-24 py-12 font-semibold bg-transparent text-label-lg text-neutral-10 border-1 border-light-aa hover:bg-light-e0 rounded-50">
            See More
          </button>
        </div>
      )}
    </div>
  );
};

export default ClassesSection;
