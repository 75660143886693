import { getCommunityProfileImgProps, getPlatformDetails } from '../utils';
import { useCallback } from 'react';

import { DescriptionEmptyCardProps } from '../constants';
import EditPencil from '@/components/common/IconsV2/EditPencil';
import NextImage from '@/components/common/NextImage';
import RoundedButton from '@/components/common/RoundedButton';
import { SectionEmptyCard } from './SectionEmptyCard';
import { SeeMoreWrapper } from '@/components/common/SeeMoreWrapper/SeeMoreWrapper';
import Tag from '@/components/common/IconsV2/Tag';
import Users from '@/components/common/IconsV2/Users';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import LinkAwareText from '@/components/common/LinkAwareText';
import NPLLockIcon from '@/components/common/IconsV2/NPLLockIcon';
import { formatNumber } from '@/utility/helpers';

const OverviewCardV2 = (props) => {
  const {
    totalMemberCount,
    title,
    hostName,
    platformName,
    loading,
    showNftModal,
    ctaLabel,
    ctaDisabled,
    profileImage,
    onCTAClick,
    priceTagText,
    isCommunityAdmin,
    onDescriptionEdit,
    about,
    isTokenGated,
    potentialSavingText,
    isDemoCommunity,
    isApplicationBased,
    isFreeCommunity
  } = props;

  const populatedMemberCount = totalMemberCount;

  const getPlatform = useCallback(() => {
    return getPlatformDetails(platformName)?.getIcon({
      customClassNames: 'w-24 h-24',
      width: 24,
      height: 24
    });
  }, [platformName]);

  const { isGtEqLg } = useWindowWidthContext();

  const renderHostRow = useCallback(() => {
    return (
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-col">
          <div className="mb-4 font-medium text-label-md text-npl-neutral-light-9">
            Hosted by
          </div>
          <div className="font-medium text-heading-xs text-npl-text-icon-on-light-surface-primary">
            {hostName}
          </div>
        </div>
      </div>
    );
  }, [hostName]);

  const renderCommunityInfoStack = useCallback(() => {
    return (
      <div className="flex flex-col items-start mb-16">
        <div className="w-full mb-12 font-semibold break-words text-heading-lg text-npl-text-icon-on-light-surface-primary">
          {title}
        </div>
        {platformName && (
          <div className="flex flex-row items-center mb-16">
            <div className="w-24 h-24 mr-8">{getPlatform()}</div>
            <div className="font-medium text-label-md text-npl-text-icon-on-light-surface-secondary">
              {getPlatformDetails(platformName)?.overviewText}
            </div>
          </div>
        )}
        <div className="flex flex-row items-center mb-16">
          <Users />
          <div className="ml-8 font-medium text-label-md text-npl-text-icon-on-light-surface-secondary">
            {formatNumber(populatedMemberCount ?? 0)}{' '}
            {populatedMemberCount > 1 ? 'Members' : 'Member'}
          </div>
        </div>
        <div className="flex flex-col mb-16">
          <div className="flex flex-row items-center">
            {isApplicationBased && isFreeCommunity ? (
              <NPLLockIcon />
            ) : (
              <Tag />
            )}
            <div className="ml-8 font-medium text-label-md text-npl-text-icon-on-light-surface-secondary ">
              {priceTagText ? priceTagText : 'Loading...'}
            </div>
          </div>
          {potentialSavingText && (
            <div
              className={` ml-32 text-label-sm ${
                potentialSavingText?.discounted
                  ? `text-npl-yellow-light-11`
                  : `text-npl-text-icon-on-light-surface-secondary`
              }`}>
              {potentialSavingText?.text}
            </div>
          )}

          {isTokenGated && (
            <button
              onClick={showNftModal}
              className="mt-4 ml-32 underline cursor-pointer text-label-sm">
              See approved NFTs
            </button>
          )}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    platformName,
    getPlatform,
    totalMemberCount,
    priceTagText,
    isTokenGated,
    showNftModal
  ]);

  const renderNormalSeeMoreDescription = useCallback(() => {
    return (
      <div className=" text-npl-text-icon-on-light-surface-primary text-body-md">
        <SeeMoreWrapper>
          <LinkAwareText text={about} />
        </SeeMoreWrapper>
      </div>
    );
  }, [about]);

  const renderDescription = useCallback(() => {
    return isCommunityAdmin && isGtEqLg ? (
      <div>
        {about ? (
          <div className="flex flex-col w-full">
            {renderNormalSeeMoreDescription()}
            <div className="flex flex-row justify-end mt-16">
              <RoundedButton
                disabled={isDemoCommunity ? true : false}
                displayType={'neutral'}
                onClick={onDescriptionEdit}>
                <div className="flex flex-row items-center">
                  <EditPencil
                    customClassNames={'w-16 h-16 mr-8'}
                    fill="#FBFCFF"
                  />
                  Edit
                </div>
              </RoundedButton>
            </div>
          </div>
        ) : (
          <SectionEmptyCard
            showShadow={false}
            {...DescriptionEmptyCardProps}
            onClickHandler={onDescriptionEdit}
            showClose={false}
          />
        )}
      </div>
    ) : (
      renderNormalSeeMoreDescription()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCommunityAdmin,
    isGtEqLg,
    about,
    renderNormalSeeMoreDescription,
    onDescriptionEdit
  ]);

  return (
    <div
      className={`p-24 pb-40 font-poppins rounded-br-0 rounded-bl-0 bg-white-default relative w-full
       `}>
      <div className="flex flex-col justify-start">
        <div className="absolute h-[80px] w-[80px] mx-auto -top-[40px] rounded-[24px] overflow-hidden border-4 border-white-default">
          <div className="relative w-full h-full ">
            <NextImage
              className="object-cover"
              {...getCommunityProfileImgProps(profileImage)}
            />
          </div>
        </div>
        <div className="flex flex-col items-start mt-[40px] md:flex-row md:justify-between">
          {renderCommunityInfoStack()}
          {
            <RoundedButton
              displayType={'primary'}
              size={'lg'}
              onClick={onCTAClick}
              isLoading={loading}
              disabled={ctaDisabled}
              customClassNames="w-full md:w-fit flex flex-row justify-center whitespace-nowrap items-center text-button-md mb-32">
              {ctaLabel}
            </RoundedButton>
          }
        </div>
        <div className="h-[1px] border-t-1 border-t-light-fof w-full mb-32 lg:mb-24 2xl:mb-[32px]" />
        <div className="w-full mb-24">{renderHostRow()}</div>
        {renderDescription()}
      </div>
    </div>
  );
};

export default OverviewCardV2;
