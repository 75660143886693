export const CardWithHeader = ({
  headerContent,
  children,
  customClassNames,
  customBodyClassNames,
  showShadow = true
}) => {
  return (
    <div
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-easing="ease"
      data-aos-duration="500"
      data-aos-once={true}
      className={`flex flex-col w-full overflow-hidden rounded-28 ${
        showShadow && 'shadow-lg'
      } ${customClassNames}`}>
      <div className="h-[44px]">{headerContent}</div>
      <div
        className={`w-full h-full bg-npl-base-white ${customBodyClassNames}`}>
        {children}
      </div>
    </div>
  );
};
