const { default: NextImage } = require('@/components/common/NextImage');
const { getCommunityOverviewImageProps } = require('../utils');
const { default: OverviewCardV2 } = require('./OverviewCardV2');
import style from './OverviewSection.module.scss';

const OverviewSectionV2 = (props) => {
  const { eyebrowTitle, backgroundImage } = props;
  return (
    <div
      className="relative flex flex-col items-center justify-center w-full rounded-[28px] shadow-lg overflow-hidden"
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-easing="ease"
      data-aos-duration="500"
      data-aos-once={true}>
      {eyebrowTitle && (
        <div className="w-full px-24 py-12 bg-npl-transparent-black-50">
          <p className="font-medium text-label-md text-light-header">
            {eyebrowTitle}
          </p>
        </div>
      )}
      {backgroundImage ? (
        <div
          key={'backgroundImage'}
          className={`w-full relative aspect-w-16 aspect-h-9 ${style['banner-image']}`}>
          <NextImage
            className="object-cover"
            {...getCommunityOverviewImageProps(backgroundImage)}
          />
        </div>
      ) : (
        <div
          key={'backgroundCss'}
          className={`w-full h-auto aspect-w-16 aspect-h-9 ${style['gradient-background']}`}
        />
      )}

      <div className="w-full">
        <OverviewCardV2 {...props} />
      </div>
    </div>
  );
};

export default OverviewSectionV2;
