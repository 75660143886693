import { default as classNames, default as classnames } from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { useOutsideMultipleComponentsClickDetect } from '@/hooks/useOutsideComponentClickDetect';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import { useMemo } from 'react';

/**
 * Headless dropdown component that only contains the logic to display or close the dropdown
 * @param options List of options to be displayed, it should be an array of objects with the following structure: {value: string, label: string}
 * @param value The currently selected value
 * @param onChange The callback function to be called when the value is changed
 * @param placeholder The placeholder text to be displayed when no value is selected
 * @param renderField The rendering logic for the select trigger, it exposes API of {option, placeholder , isOpen}
 * @param renderOption The rendering logic for the select option, it exposes API of {value, option}
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const HeadlessSelect = ({
  options,
  value,
  onChange,
  placeholder,
  placement,
  renderField,
  renderEyeBrow,
  renderOption,
  renderNoOptionsItem,
  renderChin,
  className,
  dropdownClassNames,
  customPopperClassNames,
  customWidth = false,
  disabled = false,
  dropdownOpenByDefault = false,
  setWidthToParent = false
}) => {
  const [isOpen, setIsOpen] = useState(dropdownOpenByDefault);
  const fieldRef = useRef(null);
  const parentRef = useRef(null);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const listContainerRef = useRef(null);

  const modifiers = useMemo(() => {
    const mods = [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 4]
        }
      }
    ];
    if (setWidthToParent) {
      mods.push({
        name: 'sameWidth',
        enabled: true,
        fn: ({ state }) => {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        phase: 'beforeWrite',
        requires: ['computeStyles']
      });
    }
    return mods;
  }, []);

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: placement,
      modifiers
    }
  );

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleModal = () => {
    if (disabled) return;
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    onChange(option.value);
    closeModal();
  };

  useEffect(() => {
    if (listContainerRef?.current && isOpen) {
      const selectedList = listContainerRef.current.querySelector([
        '[data-selected="true"]'
      ]);
      // the calculation below will make the selected element visible on the list and centered
      listContainerRef.current.scrollTop =
        selectedList?.offsetTop +
        selectedList?.getBoundingClientRect().height / 2 -
        listContainerRef.current.getBoundingClientRect().height / 2;
    }
  }, [isOpen, listContainerRef]);

  useOutsideMultipleComponentsClickDetect(
    [
      parentRef,
      {
        current: popperElement
      }
    ],
    closeModal
  );

  return (
    <div className={classnames('relative', className)} ref={parentRef}>
      <div
        role="button"
        tabIndex={0}
        onClick={toggleModal}
        ref={setReferenceElement}
        className={classNames('cursor-pointer select-none', {
          'cursor-not-allowed': disabled
        })}>
        {renderField({
          option: options?.find((o) => o.value === value),
          placeholder,
          isOpen,
          openModal,
          closeModal
        })}
      </div>
      {isOpen && (
        <>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={classnames(
              'fixed top-0 left-0 z-10 flex items-center justify-center h-full bg-opacity-0 bg-white-default',
              { 'w-full': !customWidth },
              customPopperClassNames
            )}
            onClick={closeModal}></div>
          {ReactDOM.createPortal(
            <div
              className=" z-[1200]"
              ref={setPopperElement}
              {...attributes.popper}
              style={{
                top: fieldRef?.current?.getBoundingClientRect().height,

                ...styles.popper
              }}>
              <ul
                className={classnames(
                  'py-8 bg-white-default rounded-8 z-20 max-h-[30vh] overflow-auto',
                  dropdownClassNames,
                  { 'w-full': !customWidth }
                )}
                style={{
                  boxShadow: ` 0px 1px 4px rgba(27, 27, 24, 0.1), 0px 4px 6px rgba(27, 27, 24, 0.04), 0px 8px 16px rgba(27, 27, 24, 0.04), 0px 10px 20px 2px rgba(27, 27, 24, 0.02), 0px 12px 24px 4px rgba(27, 27, 24, 0.04)`
                }}>
                {renderEyeBrow && (
                  <li role="menuitem">{renderEyeBrow()}</li>
                )}
                {options?.map((option, index) => {
                  return (
                    <li
                      role="menuitem"
                      key={index}
                      className="cursor-pointer"
                      data-selected={option.value === value}>
                      {renderOption({
                        value,
                        option,
                        handleOptionClick,
                        openModal,
                        closeModal
                      })}
                    </li>
                  );
                })}
                {options?.length === 0 && renderNoOptionsItem
                  ? renderNoOptionsItem()
                  : null}
                {renderChin && <li role="menuitem">{renderChin()}</li>}
              </ul>
            </div>,
            document.querySelector('body')
          )}
        </>
      )}
    </div>
  );
};

HeadlessSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  renderField: PropTypes.func,
  renderEyeBrow: PropTypes.func,
  renderChin: PropTypes.func,
  renderOption: PropTypes.func,
  className: PropTypes.string,
  placement: PropTypes.string
};

export default HeadlessSelect;
