import useUserCommunities, {
  getUserRoleInCommunity
} from '@/hooks/useUserCommunities';
import {
  applyCommunitySignUpDiscount,
  getCommunityDataService,
  getCommunityPricingByDiscountCodeService,
  getCommunityPricingServiceV2,
  submitFreeCommunityEnrollment
} from '@/services/communitiesService';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ACCESS_YOUR_COMMUNITY,
  BANNER_SECTION,
  BENEFITS_SECTION,
  CARD_GRID_SECTION,
  COURSES_SECTION,
  COURSES_SECTION_EMPTY,
  CoursesEmptyCardProps,
  DISCORD_UUID_KEY,
  EVENTS_SECTION,
  EVENTS_SECTION_EMPTY,
  GO_TO_PORTAL,
  JOIN_CTA_LABEL,
  JOIN_FREE_CTA_LABEL,
  JOIN_WAITLIST,
  LiveEventsEmptyCardProps,
  NFT_SECTION,
  ON_WAITLIST,
  PLATFORM_SECTION_EMPTY,
  PlatformEmptyCardProps,
  RESOURCES_SECTION_EMPTY,
  TESTIMONIAL_SECTION,
  defaultBannerImg,
  RESOURCES_SECTION
} from './constants';
import {
  getHighestIntervalPricing,
  getLowestIntervalPricing,
  getPlatformDetails,
  getPortalUrl,
  sectionToResponseArrayMapping
} from './utils';

import PreCheckoutStep from '@/components/common/CheckoutPartials/PreCheckoutStep';
import EmailForm from '@/components/common/Form/EmailForm';
import NasIOLogoWithText from '@/components/common/IconsV2/NasIOLogoWithText';
import Modal from '@/components/common/Modal';
import PageMetaTags from '@/components/common/PageMetaTags';
import SuccessToast from '@/components/common/SuccessToast/SuccessToast';
import { showToast } from '@/components/common/ToastContainer';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import useStartCheckout from '@/hooks/checkout/useStartCheckout';
import useAos from '@/hooks/useAos';
import usePaymentSuccessRedirectLink from '@/hooks/usePayment/usePaymentSuccessRedirectLink';
import { trackGAEvent } from '@/utility/analytics';
import { SIGN_UP_ID_SESSION_KEY } from '@/utility/checkoutConstants';
import config from '@/utility/config';
import { getTimezoneId } from '@/utility/dateHelper';
import { formatNumber } from '@/utility/helpers';
import { useRouter } from 'next/router';
import FreeCommunityRedirectModal from '../CommunityProductPage/components/FreeCommunityRedirectModal';
import BlurredBackground from '../EventPublicPage/components/BlurredBackground/BlurredBackground';
import JoinCommunityFeature from '../JoinCommunityFeature';
import WhatsappSignUpPopup from '../WhatsappSignUpPopup';
import style from './CommunityPage.module.scss';
import { CheckoutSticky } from './components/CheckoutSticky';
import ClassesSection from './components/ClassesSection';
import LiveEventsSection from './components/LiveEventsSection';
import Navbar from './components/Navbar';
import { NftModal } from './components/NftModal';
import { NftSection } from './components/NftSection';
import OverviewSectionV2 from './components/OverviewSectionV2';
import { PlatformCard } from './components/PlatformCard';
import { SectionEmptyCard } from './components/SectionEmptyCard';
import {
  CM_PORTAL_HOMEPAGE_ROUTE,
  getDiscountCodeFromUrlParams
} from '@/utility/routesHelper';
import { DISCOUNT_ENTITY_TYPE } from '@/hooks/useDiscount';
import { formatNewPricingApiToOldFormat } from '@/utility/communityHelpers';

const CommunityMainContent = (props) => {
  const { pageInfo } = props;
  const { templateData } = pageInfo;

  const { isWhatsappExperienceCommunity, config: communityConfig } =
    templateData;

  const hideSignUpOverlay = communityConfig?.hideSignUpOverlay;

  const whatsappSignUpPopupRef = useRef();

  const [pricingData, setPricingData] = useState(null);
  const [selectedPricingOption, setSelectedPricingOption] = useState(null);
  const [isWhatsappSignUpModalOpened, setIsWhatsappSignUpModalOpened] =
    useState(isWhatsappExperienceCommunity && !hideSignUpOverlay);
  const [latestTemplateData, setLatestTemplateData] =
    useState(templateData);
  const [showNftModal, setShowNftModal] = useState(false);

  const isDemoCommunity =
    !!latestTemplateData?.communityLandingPageTemplateData?.isDemo;

  // Fields can be over written in the communityLandingPageTemplateData object to replace background images etc.
  const overwrittenData =
    latestTemplateData?.communityLandingPageTemplateData?.overriddenData;

  const dataToDisplay = {
    ...latestTemplateData,
    ...overwrittenData,
    pricingData,
    eyebrowTitle: isDemoCommunity ? 'This is a demo community' : ''
  };

  const showPlatformSection = !!getPlatformDetails(
    dataToDisplay?.platformName
  );
  useAos({
    duration: 400,
    ones: true
  });

  const {
    code: communityCode,
    _id: communityId,
    isFreeCommunity,
    isWaitlist = false,
    request_approval: requestApproval = false,
    communityLandingPageTemplateData,
    applicationConfig,
    payment_methods
  } = latestTemplateData;

  const nftOnly = payment_methods?.[0]?.value === 'web3';

  const showPricing = useMemo(() => {
    return pricingData || isFreeCommunity || nftOnly || isWaitlist;
  }, [pricingData, isFreeCommunity, nftOnly, isWaitlist]);

  const router = useRouter();
  const discountCodeFromQueryParams = getDiscountCodeFromUrlParams(router);

  const { isGtEqLg } = useWindowWidthContext();

  const { loading, userCommunities } = useUserCommunities();

  const { isCommunityMember, isCommunityAdmin } = useMemo(() => {
    const { isCommunityAdmin, isCommunityMember } = getUserRoleInCommunity(
      communityCode,
      userCommunities
    );
    return { isCommunityMember, isCommunityAdmin };
  }, [communityCode, userCommunities]);

  const [isUserEnrolled, setIsUserEnrolled] = useState(false);
  const [showSuccessMessageOnToast, setShowSuccessMessageOnToast] =
    useState(false);
  const [joinCommunityModalOpened, setJoinCommunityModalOpened] =
    useState(false);
  const [adminCtaText, setAdminCtaText] = useState('Join this community');
  const [checkoutInProgress, setCheckoutInProgress] = useState(false);

  const [messageOnToast, setMessageOnToast] = useState(null);
  const needsApplicationReview =
    requestApproval && !applicationConfig?.autoApproval;

  // discounting states
  const [discountCode, setDiscountCode] = useState(null);
  const [discountedCodeData, setDiscountedCodeData] = useState();

  const metaDataProps = useMemo(() => {
    let metaObj = latestTemplateData?.metadata || {
      title: `${latestTemplateData?.title} | Nas.io Communities`,
      description: `Join ${latestTemplateData?.title}, Hosted by ${latestTemplateData?.hostName}.`,
      imageUrl: latestTemplateData?.backgroundImage || defaultBannerImg
    };
    overwrittenData?.metadata &&
      (metaObj = { ...metaObj, ...overwrittenData.metadata });
    return {
      ...metaObj,
      url: `${config.siteBaseUrl}${dataToDisplay?.slug}`,
      canonicalUrl: `${config.siteBaseUrl}${dataToDisplay?.slug}`
    };
  }, [
    latestTemplateData?.metadata,
    latestTemplateData?.title,
    latestTemplateData?.hostName,
    latestTemplateData?.backgroundImage,
    overwrittenData?.metadata,
    dataToDisplay?.slug
  ]);

  const firstPricingData = useMemo(() => {
    if (!pricingData || !pricingData.length) {
      return null;
    }
    return pricingData[0];
  }, [pricingData]);

  const { currency, pricePerMonth } = useMemo(() => {
    const currency = selectedPricingOption?.currency?.toUpperCase() || '';

    const interval =
      selectedPricingOption?.recurring?.interval === 'year'
        ? 12
        : selectedPricingOption?.recurring?.interval_count;
    return {
      currency,
      pricePerMonth: selectedPricingOption?.unit_amount / interval / 100
    };
  }, [selectedPricingOption]);

  // We need to look through all payment plans and find one with highest saving
  const discountPercentageForHighestInterval = useMemo(() => {
    // If only one plan return empty text
    if (!pricingData || pricingData?.length === 1) return null;

    // Get default pricing
    const monthlyPricing = getLowestIntervalPricing(pricingData);
    let biggestSaving = { discount: 0 };

    // Loop through all plans and find biggest saving.
    for (const pricing of pricingData) {
      const interval =
        pricing?.recurring?.interval === 'year'
          ? 12
          : pricing?.recurring?.interval_count;

      // get relative monthlyPrice for this plan
      const monthlyPrice = pricing?.unit_amount / interval;

      // calculate discount by comparing to 1-month plan
      const calculatedDiscount =
        ((monthlyPricing.unit_amount - monthlyPrice) /
          monthlyPricing.unit_amount) *
        100;
      if (calculatedDiscount > biggestSaving?.discount) {
        biggestSaving.discount = calculatedDiscount;
        biggestSaving.interval = interval;
      }
    }

    // Formulate a text to display
    return biggestSaving.discount > 0
      ? biggestSaving.interval === 12
        ? {
            text: `or save ${Math.trunc(
              biggestSaving.discount
            )}% annually`,
            discounted: true
          }
        : {
            text: `or save ${Math.trunc(biggestSaving.discount)}% per ${
              biggestSaving.interval
            } Months`,
            discounted: true
          }
      : { text: `More plans available on checkout`, discounted: false };
  }, [pricingData]);

  const formattedPricePerMonth = useMemo(() => {
    return formatNumber(pricePerMonth);
  }, [pricePerMonth]);

  const discountedPricePerMonth = useMemo(() => {
    if (discountedCodeData) {
      const { type, value } = discountedCodeData;
      if (type === 'percentage') {
        return parseFloat(
          Number(pricePerMonth * (1 - value / 100)).toFixed(2)
        ).toLocaleString();
      }
    }

    return null;
  }, [discountedCodeData, pricePerMonth]);

  const isFreeSignup = useMemo(
    () =>
      !requestApproval &&
      applicationConfig?.autoApproval &&
      (isFreeCommunity ||
        (discountedPricePerMonth === '0' &&
          selectedPricingOption?.recurring?.interval_count === 1)),
    [
      requestApproval,
      applicationConfig?.autoApproval,
      isFreeCommunity,
      discountedPricePerMonth,
      selectedPricingOption?.recurring?.interval_count
    ]
  );

  const handleNftModalClose = () => {
    setShowNftModal(false);
  };

  const { redirectLink, getRedirectLink } =
    usePaymentSuccessRedirectLink();

  const postSignupCallback = async () => {
    const discordUUID = sessionStorage.getItem(DISCORD_UUID_KEY);
    if (isFreeSignup) {
      // This is the case if there's a free Community.. We want to enroll them
      // handle enrollment here since this is the best place
      if (discountCode) {
        const { error } = await applyCommunitySignUpDiscount({
          couponCode: discountCode,
          communityCode
        });
        if (error) {
          showToast({ text: 'Invalid Coupon Code', type: 'error' });
          return;
        }
        let payload = {
          communityCode: communityCode,
          timezone: getTimezoneId(),
          discountCode: discountCode
        };
        if (discordUUID) {
          payload = { ...payload, uuid: discordUUID };
        }
        const { error: freeCommunityError } =
          await submitFreeCommunityEnrollment(payload);
        if (freeCommunityError) {
          throw new Error(freeCommunityError);
        }
      }
      handleFreeCommunityEnrollment(true);
    }
  };

  const setUserEnrolled = useCallback(() => {
    setIsUserEnrolled(true);
  }, []);

  const openJoinCommunityModal = useCallback(() => {
    setJoinCommunityModalOpened(true);
  }, []);

  const closeJoinCommunityModal = useCallback(() => {
    setJoinCommunityModalOpened(false);
  }, []);

  const containerBackgroundTransparent = true;
  const emailFormProps = useCallback(() => {
    return {
      containerBackgroundTransparent,
      closeJoinCommunityModal: closeJoinCommunityModal,
      closeModalText: 'Close',
      ctaTextLabel: 'Join Waitlist'
    };
  }, [closeJoinCommunityModal, containerBackgroundTransparent]);
  const renderJoinWaitlistForm = useCallback((emailProps) => {
    return (
      <div className={style['emailWrapper']}>
        <EmailForm {...emailProps} {...emailFormProps()} />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAboutEditClick = () => {
    let path = `${getPortalUrl(
      'settings',
      communityId
    )}&focus=description`;
    router.push(path);
  };

  const handleAddEvent = () => {
    let path = `${getPortalUrl('events/add', communityId)}`;
    router.push(path);
  };

  const handleAddResources = () => {
    let path = `${getPortalUrl('library', communityId)}`;
    router.push(path);
  };

  const handleAddPlatform = () => {
    let path = `${getPortalUrl('settings', communityId)}&tab=chat`;
    router.push(path);
  };

  const handleEditEvents = () => {
    let path = `${getPortalUrl('events', communityId)}`;
    router.push(path);
  };

  const handleEditClasses = () => {
    let path = `${getPortalUrl('library', communityId)}`;
    router.push(path);
  };

  const handleGoToPortal = useCallback(() => {
    let path = `${CM_PORTAL_HOMEPAGE_ROUTE}?activeCommunityId=${communityId}`;
    router.push(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId]);

  const handleGoToPublicPage = () => {
    let path = `${getPortalUrl('public-page', communityId)}`;
    router.push(path);
  };

  const headingStylingOnModal = containerBackgroundTransparent
    ? 'mb-8 lg:text-center md:text-left  font-semibold font-poppins text-neutral-99 lg:text-28 text-24 '
    : 'mb-8 font-bold font-poppins text-dark-33 text-16 xl:text-24';
  const paragraphStylingOnModal = containerBackgroundTransparent
    ? 'mb-24 lg:text-center  md:text-left font-normal font-poppins text-white-default text-16 '
    : 'mb-24 font-poppins text-12 text-dark-2c xl:text-16';
  const shouldPreventCheckoutRedirect =
    isFreeSignup && !requestApproval && applicationConfig?.autoApproval;

  const priceTagText = useMemo(() => {
    // order goes monthly > 6 monthly > yearly
    const selectedOption = getLowestIntervalPricing(pricingData);
    const intervalText =
      selectedOption?.recurring?.interval === 'year'
        ? 'year'
        : selectedOption?.recurring?.interval_count > 1
        ? `${selectedOption?.recurring?.interval_count} months`
        : 'month';
    const period =
      selectedPricingOption?.recurring?.interval === 'year'
        ? 'month'
        : selectedPricingOption?.recurring?.interval;
    if (discountedCodeData) {
      return (
        <>
          {selectedPricingOption?.currency?.toUpperCase() || 'USD'}{' '}
          {discountedPricePerMonth}
          <span className="ml-4 line-through text-npl-text-icon-on-light-surface-tertiary">
            {parseFloat(Number(pricePerMonth).toFixed(2))}
          </span>
          /{period}
        </>
      );
    } else if (isFreeCommunity) {
      return needsApplicationReview ? 'Application Required' : 'FREE';
    } else if (nftOnly) {
      return 'NFT-gated';
    } else if (selectedOption) {
      return `${
        selectedOption?.currency?.toUpperCase() || 'USD'
      } ${formatNumber(
        selectedOption?.unit_amount / 100 || pricePerMonth
      )}/${intervalText}`;
    } else {
      return '';
    }
  }, [
    selectedPricingOption?.recurring?.interval,
    selectedPricingOption?.currency,
    pricingData,
    discountedCodeData,
    isFreeCommunity,
    nftOnly,
    formattedPricePerMonth,
    discountedPricePerMonth,
    pricePerMonth,
    needsApplicationReview
  ]);

  // Pre-checkout hooks
  const {
    // modal state
    showPreCheckoutModal,
    setShowPreCheckoutModal,
    // login data
    isCheckingOutAsLoggedInUser,
    selectCheckoutAsLoggedInUser,
    deselectCheckoutAsLoggedInUser,
    formRef,
    onLoginSubmit,
    onLoginChange,
    loginError,
    loginLoading,
    loginInputErrors,
    // signup data
    handleCheckoutWithEmail,
    onSignupEmailChange,
    onDialCodeIdChange,
    onPhoneNumberChange,
    signupWithEmailErr,
    signUpErrors,
    // setSignUpErrors,
    signupEmail,
    dialCodeId,
    phoneNumber,
    loadingText,
    isSigningUpWithEmail,
    isSubmittingSignup,
    isUserAlreadyEnrolled,
    setIsUserAlreadyEnrolled,

    // cta
    onCommunityCheckoutClick,

    // error prompt
    promptError,
    resetPromptError,

    // free community
    shouldStartOutsideRedirectFlow,
    setShouldStartOutsideRedirectFlow,
    isCommunityEnrollmentComplete
  } = useStartCheckout({
    groupCode: communityLandingPageTemplateData?.groupCode,
    communityCode,
    slug: communityLandingPageTemplateData?.slug,
    isCommunity: true,
    isFreeWorkshop: false,
    isFreeCommunity: isFreeCommunity,
    shouldPreventCheckoutRedirect,
    navigateToCommunity: goToMemberPortal,
    postSignupCallback: async () => {
      await postSignupCallback();
    },
    discountCodeFromQueryParams
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function goToMemberPortal() {
    const route =
      isUserAlreadyEnrolled && communityId
        ? `${config.memberPortalPath}/communities/${communityId}/home`
        : `${config.memberPortalPath}/communities`;

    return router.push(route);
  }

  const onCTAClick = useCallback(async () => {
    if (isCommunityAdmin) {
      return handleGoToPortal();
    }

    if (isUserAlreadyEnrolled) {
      // GO TO COMMUNITY PORTAL
      return goToMemberPortal();
    }

    if (isWaitlist) {
      return openJoinCommunityModal();
    }
    setCheckoutInProgress(true);
    // Fetch latest community data to make sure we have the latest pricing options and application gated status
    const { data, error } = await getCommunityDataService(
      dataToDisplay?.slug
    );
    if (!error) {
      setLatestTemplateData(data);
    }
    // TODO: Make this usememo into a state variable when multi month is enabled on community landing pages

    // if the community is a whatsapp beta community, open the whatsapp signup popup instead of the community sign up popup
    if (isWhatsappExperienceCommunity) {
      setIsWhatsappSignUpModalOpened(true);
      setCheckoutInProgress(false);
      return;
    }

    // We want to send the interval and interval count with the highest interval
    const selectedOption = getLowestIntervalPricing(pricingData);

    return onCommunityCheckoutClick({
      communityCode,
      interval: selectedOption?.recurring?.interval ?? 'month',
      intervalCount: selectedOption?.recurring?.interval_count ?? 1,
      priceId: selectedOption?.id
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCommunityAdmin,
    isUserAlreadyEnrolled,
    isWaitlist,
    dataToDisplay?.slug,
    isWhatsappExperienceCommunity,
    pricingData,
    onCommunityCheckoutClick,
    communityCode,
    handleGoToPortal,
    goToMemberPortal,
    openJoinCommunityModal
  ]);

  const getCTAlabelButton = useCallback(() => {
    // if (role === roles.admin) {
    //   return GO_TO_PORTAL;
    // }
    if (isCommunityAdmin) {
      return GO_TO_PORTAL;
    }
    if (isUserAlreadyEnrolled) {
      return ACCESS_YOUR_COMMUNITY;
    }
    if (isWaitlist && isUserEnrolled) {
      return ON_WAITLIST;
    }
    if (isWaitlist) {
      return JOIN_WAITLIST;
    }
    if (isFreeSignup) {
      return JOIN_FREE_CTA_LABEL;
    }
    return JOIN_CTA_LABEL;
  }, [
    isCommunityAdmin,
    isUserAlreadyEnrolled,
    isWaitlist,
    isUserEnrolled,
    isFreeSignup
  ]);

  const getCTADisabled = useCallback(() => {
    if (isDemoCommunity) return true;
    return false;
  }, [isDemoCommunity]);

  const getCTALocked = useCallback(() => {
    return (
      !isFreeSignup &&
      !isUserEnrolled &&
      !isUserAlreadyEnrolled &&
      !isCommunityAdmin
      // role !== roles.admin
    );
  }, [
    isFreeSignup,
    isUserEnrolled,
    isUserAlreadyEnrolled,
    isCommunityAdmin
    // role
  ]);

  const ctaProps = useCallback(
    () => ({
      onCTAClick: onCTAClick,
      ctaDisabled: getCTADisabled(),
      ctaLabel: getCTAlabelButton(),
      ctaLocked: getCTALocked()
    }),
    [getCTADisabled, getCTALocked, getCTAlabelButton, onCTAClick]
  );

  const renderJoinWaitlistFormSubSection = useCallback(
    (emailProps) => {
      return (
        <>
          <h2 className={headingStylingOnModal}>
            {latestTemplateData?.title}
          </h2>
          <p className={paragraphStylingOnModal}>
            We&apos;ll let you know as soon as this community starts!
          </p>
          {renderJoinWaitlistForm(emailProps)}
        </>
      );
    },
    [
      headingStylingOnModal,
      paragraphStylingOnModal,
      renderJoinWaitlistForm,
      latestTemplateData?.title
    ]
  );

  const renderCustomJoinWaitlistInput = useCallback(
    (emailProps) => (
      <Modal
        showCloseIcon={false}
        containerBackgroundTransparent={containerBackgroundTransparent}
        size="md"
        open={true}
        onClose={closeJoinCommunityModal}>
        {renderJoinWaitlistFormSubSection(emailProps)}
      </Modal>
    ),
    [
      closeJoinCommunityModal,
      renderJoinWaitlistFormSubSection,
      containerBackgroundTransparent
    ]
  );

  const handleFreeCommunityEnrollment = useCallback(async () => {
    const sessionBookingId = sessionStorage.getItem(
      SIGN_UP_ID_SESSION_KEY
    );
    if (isCommunityEnrollmentComplete) {
      if (!needsApplicationReview) {
        await getRedirectLink({
          bookingId: sessionBookingId,
          isCommunity: true
        });
      }
    }
  }, [
    getRedirectLink,
    isCommunityEnrollmentComplete,
    needsApplicationReview
  ]);

  const onWhatsappSignUpPopupClose = () => {
    if (
      whatsappSignUpPopupRef.current &&
      whatsappSignUpPopupRef.current.animate
    ) {
      const swipeDownAnimation = whatsappSignUpPopupRef.current.animate(
        [
          { transform: 'translateY(0)' },
          { transform: 'translateY(100%)' }
        ],
        { duration: 450 }
      );

      swipeDownAnimation.onfinish = () =>
        setIsWhatsappSignUpModalOpened(false);
    } else setIsWhatsappSignUpModalOpened(false);
  };

  useEffect(() => {
    // setting this because of the UI overrides in Modal feature
    if (
      document.body &&
      (containerBackgroundTransparent === false ||
        containerBackgroundTransparent === undefined)
    ) {
      document.body.style.overflow = 'auto';
    }
  }, [containerBackgroundTransparent, joinCommunityModalOpened]);

  useEffect(() => {
    const fetchPricingData = async () => {
      const { data, error } = await getCommunityPricingServiceV2(
        communityId
      );
      if (error) {
        console.log('Error fetching community data', error);
        return;
      }

      const formattedNewPricingDetails =
        formatNewPricingApiToOldFormat(data);

      formattedNewPricingDetails?.sort(function (a, b) {
        return a?.recurring?.interval_count - b?.recurring?.interval_count;
      });

      setPricingData(formattedNewPricingDetails);
    };
    // if page in fallback it means its being built, so leave in loading state, else set loading false
    if (latestTemplateData?.stripeProductId) {
      fetchPricingData();
    }
  }, [communityId]);

  useEffect(() => {
    // logic for checking if the user is already enrolled in the waitlist
    if (containerBackgroundTransparent) {
      if (isWaitlist) {
        const communityObj = JSON.parse(
          sessionStorage.getItem('communityCode')
        );

        if (communityObj) {
          if (communityCode in communityObj) {
            setUserEnrolled(true);
          }
        } else {
          // setJoinCommunityModalOpened(true);
        }
      }
    }
  }, [
    communityCode,
    containerBackgroundTransparent,
    isWaitlist,
    setUserEnrolled
  ]);

  useEffect(() => {
    if (isCommunityMember) {
      setIsUserAlreadyEnrolled(true);
    }
  }, [isCommunityMember, setIsUserAlreadyEnrolled]);

  useEffect(() => {
    if (shouldStartOutsideRedirectFlow) {
      setShowPreCheckoutModal(false);
    }
  }, [setShowPreCheckoutModal, shouldStartOutsideRedirectFlow]);

  useEffect(() => {
    if (shouldStartOutsideRedirectFlow) {
      handleFreeCommunityEnrollment();
    }
  }, [handleFreeCommunityEnrollment, shouldStartOutsideRedirectFlow]);

  useEffect(() => {
    if (isCommunityEnrollmentComplete && isFreeSignup)
      handleFreeCommunityEnrollment();
  }, [
    handleFreeCommunityEnrollment,
    isCommunityEnrollmentComplete,
    isFreeSignup
  ]);

  useEffect(() => {
    if (redirectLink && isFreeSignup) {
      window.location.href = redirectLink;
    }
  }, [isFreeSignup, redirectLink]);

  useEffect(() => {
    if (showPricing && !selectedPricingOption) {
      setSelectedPricingOption(firstPricingData);
    }
  }, [
    pricePerMonth,
    pricingData,
    firstPricingData,
    selectedPricingOption,
    showPricing
  ]);

  useEffect(() => {
    const { trackingPixels } = latestTemplateData || {};
    if (trackingPixels) {
      const { facebookPixel, tikTokPixel } = trackingPixels || {};

      // dynamically import the pixel libraries and initialize them because they'll throw errors if not imported in useEffect refer to
      // https://github.com/vercel/next.js/tree/canary/examples/with-facebook-pixel for more info
      if (facebookPixel) {
        import('react-facebook-pixel')
          .then((rPixel) => rPixel.default)
          .then((ReactPixel) => {
            ReactPixel.init(facebookPixel);
            ReactPixel.pageView();
          });
      }

      if (tikTokPixel) {
        import('tiktok-pixel')
          .then((tPixel) => tPixel.default)
          .then((TiktokPixel) => {
            TiktokPixel.init(tikTokPixel);
            TiktokPixel.pageView();
          });
      }
    }
  }, [latestTemplateData]);

  const renderCustomSection = useCallback(
    (sectionName) => {
      const section =
        communityLandingPageTemplateData?.customSectionData?.[sectionName];
      switch (section?.type) {
        case CARD_GRID_SECTION:
          return null;
        // return <CardGrid {...section?.data} />;
        case TESTIMONIAL_SECTION:
          return null;
        // return (
        //   <Testimonials
        //     isDesktopView={isGtEqLg}
        //     testimonialProps={section?.data}
        //   />
        // );
        default:
          return null;
      }
    },
    [communityLandingPageTemplateData?.customSectionData]
  );

  useEffect(() => {
    if (isFreeCommunity) {
      setAdminCtaText('Anyone can join for free');
    } else if (nftOnly) {
      setAdminCtaText('NFT Gated');
    } else if (pricingData) {
      setAdminCtaText(
        `${formattedPricePerMonth} ${selectedPricingOption?.currency?.toUpperCase()} per month`
      );
    }
  }, [
    nftOnly,
    isFreeCommunity,
    pricingData,
    formattedPricePerMonth,
    selectedPricingOption?.currency
  ]);

  useEffect(() => {
    setDiscountCode(discountCodeFromQueryParams);
    const getDiscountedPrice = async (discountCode) => {
      const { data, error } =
        await getCommunityPricingByDiscountCodeService({
          discountCode,
          communityCode,
          entityObjectId: communityId,
          entityType: DISCOUNT_ENTITY_TYPE.SUBSCRIPTION
        });
      if (error) {
        return;
      }
      setDiscountedCodeData(data);
    };

    // TODO: for testin PF-86
    console.log(discountCodeFromQueryParams);
    if (
      discountCodeFromQueryParams &&
      discountCodeFromQueryParams.length > 0
    )
      getDiscountedPrice(discountCodeFromQueryParams);
  }, [communityCode, communityId, discountCodeFromQueryParams]);

  useEffect(() => {
    if (communityId) {
      trackGAEvent('public_community_page_visit', {
        communityId: communityId,
        communityCode: communityCode
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId]);

  useEffect(() => {
    const { uuid } = router.query;

    // store the uuid in the session storage if it exists
    if (uuid) {
      sessionStorage.setItem(DISCORD_UUID_KEY, uuid);
    }
  }, [router.query]);

  const isArrayEmpty = (array) => {
    return array?.length < 1;
  };

  // Calculate which section are empty and has a empty card to display, primarily its only the fields in sectionToResponseArrayMapping
  const getEmptySectionsToShow = useCallback(() => {
    let emptySections = [];
    if (!dataToDisplay?.platformName) {
      emptySections.push('platformNameEmpty');
    }
    communityLandingPageTemplateData?.sectionOrder.forEach((section) => {
      if (Array.isArray(sectionToResponseArrayMapping[section])) {
        // Check each array specified
        if (
          sectionToResponseArrayMapping[section]?.findIndex(
            (fieldName) => !isArrayEmpty(dataToDisplay?.[fieldName])
          ) < 0
        ) {
          emptySections.push(`${section}Empty`);
        }
      } else if (
        sectionToResponseArrayMapping[section] &&
        isArrayEmpty(dataToDisplay[sectionToResponseArrayMapping[section]])
      ) {
        // Empty sections for main section is just suffixed with 'Empty'
        emptySections.push(`${section}Empty`);
      }
    });

    return emptySections;
  }, [dataToDisplay]);

  // Filter out empty sections
  const sectionsToRender = useMemo(() => {
    let updatedSections = [];

    if (!communityLandingPageTemplateData?.sectionOrder)
      return updatedSections;

    updatedSections =
      communityLandingPageTemplateData?.sectionOrder.filter((section) => {
        if (Array.isArray(sectionToResponseArrayMapping[section])) {
          // Check each array specified
          return (
            sectionToResponseArrayMapping[section]?.findIndex(
              (fieldName) => !isArrayEmpty(dataToDisplay?.[fieldName])
            ) >= 0
          );
        } else if (sectionToResponseArrayMapping[section]) {
          return !isArrayEmpty(
            dataToDisplay[sectionToResponseArrayMapping[section]]
          );
        }
        return true;
      });

    if (nftOnly) updatedSections.push(NFT_SECTION);

    // Add empty sections at the end if they need to be shown, they will always be added at the bottom
    updatedSections = [...updatedSections, ...getEmptySectionsToShow()];

    return updatedSections;
  }, [communityLandingPageTemplateData]);

  const renderSections = useCallback(() => {
    return sectionsToRender.map((section, index) => {
      switch (section) {
        case BANNER_SECTION:
          return (
            <>
              <OverviewSectionV2
                key={index}
                {...dataToDisplay}
                {...ctaProps(false)}
                // role={role}
                onDescriptionEdit={handleAboutEditClick}
                loading={loading || checkoutInProgress}
                priceTagText={priceTagText}
                isCommunityAdmin={isCommunityAdmin}
                adminCtaText={adminCtaText}
                nftOnly={nftOnly}
                showNftModal={() => setShowNftModal(true)}
                onAdminCtaClick={handleGoToPortal}
                isFreeCommunity={isFreeCommunity}
                formattedPricePerMonth={formattedPricePerMonth}
                potentialSavingText={discountPercentageForHighestInterval}
                isDemoCommunity={isDemoCommunity}
                isApplicationBased={needsApplicationReview}
              />
              {showPlatformSection && (
                <PlatformCard
                  platform={dataToDisplay?.platformName}
                  communityName={dataToDisplay?.title}
                />
              )}
            </>
          );
        case PLATFORM_SECTION_EMPTY:
          return (
            isCommunityAdmin &&
            isGtEqLg && (
              <SectionEmptyCard
                {...PlatformEmptyCardProps}
                onClickHandler={handleAddPlatform}
                isDemoCommunity={isDemoCommunity}
              />
            )
          );
        // CLuster together and only display once since its a grouped section
        // (default so will always show for now)
        case EVENTS_SECTION:
          return (
            <LiveEventsSection
              upcomingEvents={dataToDisplay?.upcomingEvents}
              pastEvents={dataToDisplay?.pastEvents}
              key={index}
              handleEditEventsClick={handleEditEvents}
              sectionTitle="Live Events"
              showAdminContent={isCommunityAdmin}
              isDemoCommunity={isDemoCommunity}
            />
          );
        case EVENTS_SECTION_EMPTY:
          // TODO
          return (
            isCommunityAdmin &&
            isGtEqLg && (
              <SectionEmptyCard
                {...LiveEventsEmptyCardProps}
                onClickHandler={handleAddEvent}
                showPlusIcon={true}
                isDemoCommunity={isDemoCommunity}
              />
            )
          );
        case RESOURCES_SECTION:
          return null;
        // DEV-664 - Combine Resources to Classes Section
        // return (
        //   <ResourcesSection
        //     resources={dataToDisplay?.collections}
        //     key={index}
        //     sectionTitle="Resources"
        //     handleEditClick={handleEditResources}
        //     showAdminContent={isCommunityAdmin}
        //   />
        // );
        case RESOURCES_SECTION_EMPTY:
          return null;
        // DEV-664 - Combine Resources to Classes Section
        // return (
        //   isCommunityAdmin &&
        //   isGtEqLg && (
        //     <SectionEmptyCard
        //       {...ResourcesEmptyCardProps}
        //       onClickHandler={handleAddResources}
        //       showPlusIcon={true}
        //       isDemoCommunity={isDemoCommunity}
        //     />
        //   )
        // );
        // case RESOURCES_SECTION:
        case COURSES_SECTION:
          return (
            <ClassesSection
              courses={dataToDisplay?.communityFolders}
              key={index}
              sectionTitle="Content"
              showAdminContent={isCommunityAdmin}
              handleEditClick={handleEditClasses}
              isDemoCommunity={isDemoCommunity}
            />
          );
        case COURSES_SECTION_EMPTY:
          // TODO
          return (
            isCommunityAdmin &&
            isGtEqLg && (
              <SectionEmptyCard
                {...CoursesEmptyCardProps}
                onClickHandler={handleAddResources}
                showPlusIcon={true}
                isDemoCommunity={isDemoCommunity}
              />
            )
          );
        case BENEFITS_SECTION:
          // TT-1548: Remove this section for the time being.
          return null;
        // return (
        //   <BenefitsSection
        //     benefits={dataToDisplay?.benefits}
        //     sectionTitle="What you get"
        //   />
        // );
        case NFT_SECTION:
          return (
            <NftSection
              nfts={dataToDisplay?.communityWeb3DiscountMethods}
            />
          );
        default:
          // Add custom sections on this function
          return renderCustomSection(section);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    communityLandingPageTemplateData?.sectionOrder,
    latestTemplateData,
    overwrittenData,
    adminCtaText,
    nftOnly,
    handleGoToPortal,
    isFreeCommunity,
    formattedPricePerMonth,
    handleAboutEditClick,
    sectionsToRender,
    isGtEqLg,
    showPricing,
    pricingData,
    selectedPricingOption,
    currency,
    renderCustomSection
  ]);

  return (
    <div
      className={`${style.wrapper} relative`}
      key={joinCommunityModalOpened}>
      {showSuccessMessageOnToast && (
        <SuccessToast
          msg={messageOnToast}
          position="fixed"
          top="bottom"
          right="right"
          centerHorizontal={true}
        />
      )}
      {/* <AvoidOptimizeFlicker /> */}
      <Navbar
        sticky={false}
        isCommunityMember={isUserAlreadyEnrolled}
        isCommunityAdmin={isCommunityAdmin}
        loading={loading}
        handleAdminEdit={handleGoToPublicPage}
        navigateToLearnPortal={goToMemberPortal}
        navigateToAdminPortal={handleGoToPortal}
        isDemoCommunity={isDemoCommunity}
      />

      <CheckoutSticky
        ctaText={dataToDisplay?.signupCtaLabel}
        priceText={priceTagText}
        communityName={dataToDisplay?.title}
        loading={loading}
        {...ctaProps(false)}
      />

      {latestTemplateData && (
        <div className="px-24 overflow-hidden ">
          <PageMetaTags {...metaDataProps} />
          <BlurredBackground
            bannerImg={dataToDisplay?.backgroundImage}
            alt={dataToDisplay?.title}
          />
          <div className="flex flex-col items-center justify-center gap-16 w-full mt-24 lg:mx-auto lg:max-w-[800px]">
            {renderSections()}
          </div>
          <div className="mt-40 mb-[160px] w-full flex flex-col items-center ">
            <span className="mb-4 font-medium text-npl-text-icon-on-light-surface-secondary text-label-sm">
              Built with
            </span>
            <a href="/">
              <NasIOLogoWithText />
            </a>
          </div>

          {nftOnly && (
            <NftModal
              show={showNftModal}
              onClose={handleNftModalClose}
              signupCtaLabel={dataToDisplay?.signupCtaLabel}
              {...ctaProps(false)}
              nfts={
                dataToDisplay?.communityWeb3DiscountMethods
              }></NftModal>
          )}
        </div>
      )}

      {/* <NewFooter
        showNavLinks={false}
        showSocialMediaIcons={false}
        showBackToTop={false}
      /> */}

      {/* -- Modals -- */}
      {joinCommunityModalOpened && (
        <JoinCommunityFeature
          containerBackgroundTransparent={containerBackgroundTransparent}
          renderInput={renderCustomJoinWaitlistInput}
          onFinish={closeJoinCommunityModal}
          onEnrollment={setUserEnrolled}
          communityCode={communityCode}
          setShowSuccessMessageOnToast={setShowSuccessMessageOnToast}
          setMessageOnToast={setMessageOnToast}
        />
      )}
      {shouldStartOutsideRedirectFlow && (
        <FreeCommunityRedirectModal
          onClose={() => {
            setShouldStartOutsideRedirectFlow(false);
          }}
        />
      )}
      {showPreCheckoutModal && (
        <PreCheckoutStep
          // modal state
          showModal={showPreCheckoutModal}
          onClose={() => {
            setCheckoutInProgress(false);
            setShowPreCheckoutModal(false);
          }}
          //UI Stuff
          // stepperProps={stepperProps}
          title={`Join ${latestTemplateData?.title}`}
          // title={'Join the NFT Pro Community'}
          // subtitle={'Select your preferred mode of signup'}
          // login data
          selectCheckoutAsLoggedInUser={selectCheckoutAsLoggedInUser}
          deselectCheckoutAsLoggedInUser={deselectCheckoutAsLoggedInUser}
          isCheckingOutAsLoggedInUser={isCheckingOutAsLoggedInUser}
          formRef={formRef}
          onLoginSubmit={onLoginSubmit}
          onLoginChange={onLoginChange}
          loginError={loginError}
          loginLoading={loginLoading}
          // signup data
          handleCheckoutWithEmail={handleCheckoutWithEmail}
          onSignupEmailChange={onSignupEmailChange}
          onDialCodeIdChange={onDialCodeIdChange}
          onPhoneNumberChange={onPhoneNumberChange}
          signupWithEmailErr={signupWithEmailErr}
          loginInputErrors={loginInputErrors}
          checkoutErrors={signUpErrors}
          signupEmail={signupEmail}
          phoneNumber={phoneNumber}
          dialCodeId={dialCodeId}
          loadingText={loadingText}
          isSigningUpWithEmail={isSigningUpWithEmail}
          isSubmittingSignup={isSubmittingSignup}
          isUserAlreadyEnrolled={isUserAlreadyEnrolled}
          setIsUserAlreadyEnrolled={setIsUserAlreadyEnrolled}
          showPhoneField={false}
          checkoutWithEmailCtaLabel="Continue"
          // error prompt
          promptError={promptError}
          resetPromptError={resetPromptError}
          // payment type
          isFreeCommunity={isFreeCommunity}
          shouldPreventCheckoutRedirect={shouldPreventCheckoutRedirect}
        />
      )}

      {/* Whatsapp member Signup flow */}
      {isWhatsappSignUpModalOpened && (
        <WhatsappSignUpPopup
          ref={whatsappSignUpPopupRef}
          onClose={onWhatsappSignUpPopupClose}
          isCommunityMember={isCommunityMember}
          communityInfo={dataToDisplay}
          priceTagText={priceTagText}
          discountCodeFromQueryParams={discountCodeFromQueryParams}
        />
      )}
    </div>
  );
};

export default CommunityMainContent;
