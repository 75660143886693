import React from 'react';

const LeadIcon = ({ fillColor = '#F5F5F5' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 9.75049H9.75V13.5005C9.75 13.913 9.4125 14.2505 9 14.2505C8.5875 14.2505 8.25 13.913 8.25 13.5005V9.75049H4.5C4.0875 9.75049 3.75 9.41299 3.75 9.00049C3.75 8.58799 4.0875 8.25049 4.5 8.25049H8.25V4.50049C8.25 4.08799 8.5875 3.75049 9 3.75049C9.4125 3.75049 9.75 4.08799 9.75 4.50049V8.25049H13.5C13.9125 8.25049 14.25 8.58799 14.25 9.00049C14.25 9.41299 13.9125 9.75049 13.5 9.75049Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default LeadIcon;
