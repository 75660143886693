export const sideImageData = {
  alt: 'side image login',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/loginPage/login-manager-banner.png',
    width: '100%',
    height: '100%'
  },
  desktopImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/loginPage/login-manager-banner.png',
    width: 272,
    height: 787
  }
};

export const NO_COMMUNITY_ERROR =
  'Learner does not have any community subscriptions';
