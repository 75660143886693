import React from 'react';

const NPLLockIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8V10.1503C18.2826 10.2123 18.5539 10.3024 18.816 10.436C19.5686 10.8195 20.1805 11.4314 20.564 12.184C20.8113 12.6694 20.9099 13.1861 20.9558 13.7482C21 14.2894 21 14.9537 21 15.7587V16.2413C21 17.0463 21 17.7106 20.9558 18.2518C20.9099 18.8139 20.8113 19.3306 20.564 19.816C20.1805 20.5686 19.5686 21.1805 18.816 21.564C18.3306 21.8113 17.8139 21.9099 17.2518 21.9558C16.7106 22 16.0463 22 15.2413 22H8.75868C7.95372 22 7.28936 22 6.74817 21.9558C6.18608 21.9099 5.66937 21.8113 5.18404 21.564C4.43139 21.1805 3.81947 20.5686 3.43597 19.816C3.18868 19.3306 3.09012 18.8139 3.04419 18.2518C2.99998 17.7106 2.99999 17.0463 3 16.2413V15.7587C2.99999 14.9537 2.99998 14.2894 3.04419 13.7482C3.09012 13.1861 3.18868 12.6694 3.43597 12.184C3.81947 11.4314 4.43139 10.8195 5.18404 10.436C5.44614 10.3024 5.71739 10.2123 6 10.1503V8ZM8 10.0016C8.23771 9.99999 8.4904 10 8.7587 10H15.2413C15.5096 10 15.7623 9.99999 16 10.0016V8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8V10.0016ZM6.91104 12.0376C6.47262 12.0734 6.24842 12.1383 6.09202 12.218C5.7157 12.4097 5.40973 12.7157 5.21799 13.092C5.1383 13.2484 5.07337 13.4726 5.03755 13.911C5.00078 14.3611 5 14.9434 5 15.8V16.2C5 17.0566 5.00078 17.6389 5.03755 18.089C5.07337 18.5274 5.1383 18.7516 5.21799 18.908C5.40973 19.2843 5.7157 19.5903 6.09202 19.782C6.24842 19.8617 6.47262 19.9266 6.91104 19.9624C7.36113 19.9992 7.94342 20 8.8 20H15.2C16.0566 20 16.6389 19.9992 17.089 19.9624C17.5274 19.9266 17.7516 19.8617 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C18.8617 18.7516 18.9266 18.5274 18.9624 18.089C18.9992 17.6389 19 17.0566 19 16.2V15.8C19 14.9434 18.9992 14.3611 18.9624 13.911C18.9266 13.4726 18.8617 13.2484 18.782 13.092C18.5903 12.7157 18.2843 12.4097 17.908 12.218C17.7516 12.1383 17.5274 12.0734 17.089 12.0376C16.6389 12.0008 16.0566 12 15.2 12H8.8C7.94342 12 7.36113 12.0008 6.91104 12.0376Z"
        fill="#1B1B18"
        fillOpacity="0.65"
      />
    </svg>
  );
};

export default NPLLockIcon;
