import RoundedButton from '@/components/common/RoundedButton';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import { useTransition, animated } from '@react-spring/web';
import { useEffect, useState } from 'react';

export const CheckoutSticky = ({
  onCTAClick,
  ctaLabel,
  ctaDisabled,
  priceText,
  communityName,
  loading
}) => {
  const { isGtEqMd } = useWindowWidthContext();

  const [showSticky, setShowSticky] = useState(false);

  const getShowHeight = isGtEqMd ? 536 : 500;

  const handleShowSticky = () => {
    if (window.scrollY > getShowHeight) {
      setShowSticky(true);
    } else {
      setShowSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleShowSticky);
    return () => {
      window.removeEventListener('scroll', handleShowSticky);
    };
  }, []);

  // Animations
  const transitionStickyDesktop = useTransition(showSticky, {
    from: { y: -76 },
    enter: { y: 0 },
    leave: { y: -76 },
    reverse: showSticky
  });

  const transitionStickyMobile = useTransition(showSticky, {
    from: { y: 76 },
    enter: { y: 0 },
    leave: { y: 76 },
    reverse: showSticky
  });

  const renderSticky = (styles) => (
    <animated.div
      style={styles}
      className={`flex flex-row z-[1000] justify-center py-16 px-24 h-[76px] md:px-40 bg-npl-base-white fixed left-0 w-full shadow-npl-styles-shadow-02  ${
        isGtEqMd ? 'top-0 ' : 'bottom-0'
      }`}>
      <div className="flex flex-row  justify-between w-full lg:max-w-[800px] xl:max-w-[960px]">
        <div className="flex flex-col justify-center">
          <div className="font-semibold text-label-md text-npl-text-icon-on-light-surface-primary mb-8">
            {communityName}
          </div>
          <div className="font-medium text-label-md text-npl-text-icon-on-light-surface-secondary mb-4">
            {priceText}
          </div>
        </div>
        {
          <RoundedButton
            displayType={'primary'}
            isLoading={loading}
            onClick={onCTAClick}
            disabled={ctaDisabled}
            customClassNames="text-npl-text-icon-on-light-surface-primary text-button-md !font-medium">
            {ctaLabel}
          </RoundedButton>
        }
      </div>
    </animated.div>
  );

  return isGtEqMd
    ? transitionStickyDesktop(
        (styles, item) => item && renderSticky(styles)
      )
    : transitionStickyMobile(
        (styles, item) => item && renderSticky(styles)
      );
};
