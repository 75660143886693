import React from 'react';

const className = 'c-IconV2-NasIoLogoDark';

const NasIoLogoDark = ({
  width = '36',
  height = '36',
  customClassNames
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      className={`${className} ${customClassNames}`}>
      <path
        d="M4.14757 20.6172C2.61748 19.0871 2.61748 16.6064 4.14757 15.0762C5.67766 13.5462 8.15843 13.5462 9.68854 15.0762L20.7705 26.1583C22.3007 27.6883 22.3007 30.1693 20.7705 31.6993C19.2405 33.2293 16.7596 33.2293 15.2295 31.6993L4.14757 20.6172Z"
        fill="#2C2C2C"
      />
      <path
        d="M20.7706 31.6993C19.2404 33.2292 16.7597 33.2292 15.2296 31.6993C13.6995 30.1692 13.6995 27.6883 15.2296 26.1581L26.3116 15.0762C27.8417 13.5462 30.3225 13.5462 31.8525 15.0762C33.3827 16.6064 33.3827 19.0871 31.8525 20.6173L20.7706 31.6993Z"
        fill="#2C2C2C"
      />
      <path
        d="M17.9889 11.5675C20.3547 11.5675 22.2727 9.6496 22.2727 7.28376C22.2727 4.91791 20.3547 3 17.9889 3C15.6229 3 13.7051 4.91791 13.7051 7.28376C13.7051 9.6496 15.6229 11.5675 17.9889 11.5675Z"
        fill="#FBC91B"
      />
    </svg>
  );
};

export default NasIoLogoDark;
